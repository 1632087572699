import axios from "axios";

/**
 * 获取排产计划列表
 */
export const fetchProductPlanList = params => {
  return axios({
    url: "/dtsum/zongtong/order/ProductPlanController/productPlanHomePage",
    method: "GET",
    params
  });
};

/**
 * 获取生产机台列表，机台弹窗中展示，ps：这个接口在 语雀接口文档——纵通1.2web端 里面
 */
export const fetchDeviceList = paramObj => {
  return axios({
    url: "/dtsum/zongtong/order/ProductPlanController/deviceList",
    method: "GET",
    params: { param: paramObj }
  });
};

/**
 * 量产指示单追加机台号，追加操作，需要把之前的机台号也一起发给后端
 */
export const fetchAppendDevice = data => {
  return axios({
    url: "/dtsum/zongtong/order/ProductPlanController/addMachineNo",
    method: "POST",
    data
  });
};

/**
 * 指定完工，可以选多个，若操作成功，则状态会变成 指定完工
 */
// export const fetchCompleteProduction = data => {
//   return axios({
//     url: "/dtsum/zongtong/order/ProductExecuteController/massProductionFinishWork",
//     method: "POST",
//     data
//   });
// };
