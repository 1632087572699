<template>
  <div id="page-mass-produce">
    <!-- 筛选区块 -->
    <div class="common-filter-block">
      <div class="common-filter-item">
        <Select v-model="listParam.searchType" style="width: 120px">
          <Option v-for="item in searchTypeList" :value="item.value" :key="item.value">{{
            item.label
          }}</Option>
        </Select>
        <Input
          placeholder="请输入搜索内容"
          v-model.trim="listParam.searchText"
          @keyup.enter.native="getTableListData"
          style="width: 200px"
        />
      </div>
      <div class="common-filter-item">
        <span style="text-align: right; margin-right: 10px">机台号</span>
        <Input
          placeholder="请输入搜索内容"
          v-model.trim="listParam.machineNo"
          @keyup.enter.native="getTableListData"
          style="width: 180px"
        />
      </div>
      <div class="common-filter-item">
        <span style="text-align: right; margin-right: 10px">状态</span>
        <Select v-model="listParam.ifScheduling" @on-change="getTableListData" style="width: 120px">
          <Option v-for="item in productionStatusList" :value="item.value" :key="item.value">{{
            item.label
          }}</Option>
        </Select>
      </div>
      <!-- <div class="common-filter-item">
        <span style="text-align: right; margin-right: 10px">指定完工时间</span>
        <DatePicker
          type="date"
          placeholder="请选择日期"
          v-model="listParam.SpecifyFinishTime"
          @on-change="handleDateChange"
        />
      </div> -->
    </div>

    <!-- 表格操作区块 -->
    <div class="common-action-block">
      <div class="common-action-item" @click="getTableListData">
        <i class="iconfont iconicon-shuaxin"></i>
        刷新
      </div>
      <!-- <div class="common-action-item" @click="completeProduction">
        <i class="iconfont iconicon-queren"></i>
        指定完工
      </div> -->
      <div class="common-action-item" @click="exportTableData">
        <i class="iconfont iconicon-daochu"></i>
        导出
      </div>
    </div>

    <!-- 表格 -->
    <!-- ref="selection" -->
    <!-- @on-selection-change="selectionChange" -->
    <Table :border="true" :loading="loading" :columns="tableColumns" :data="tableData">
      <template slot-scope="{ row }" slot="action">
        <span
          v-if="row.status === 3"
          style="color: #256de6; cursor: pointer"
          @click="clickAppendMachine(row)"
          >追加机台</span
        >
        <span v-else style="color: rgba(37, 109, 230, 0.45)">追加机台</span>
      </template>

      <template slot-scope="{ row }" slot="greyClothSheetNo">
        <span style="color: #256de6; cursor: pointer" @click="goToDetailPage(row)">{{
          row.greyClothSheetNo
        }}</span>
      </template>
    </Table>
    <!-- 分页 -->
    <Page
      class-name="common-page"
      show-sizer
      show-elevator
      show-total
      :total="listDataTotal"
      :page-size="listParam.pageSize"
      :current="listParam.pageNum"
      @on-page-size-change="pageSizeChange"
      @on-change="pageNumberChange"
    />

    <AppendMachineModal
      :is-show-modal="isShowMachineModal"
      :ztpp-id="currentZtppId"
      :current-device-list="currentMachineList"
      @visible-change="isShowMachineModal = $event"
      @on-success="getTableListData"
    />
  </div>
</template>

<script>
import { searchTypeList, productionStatusList } from "./constant";
import { fetchProductPlanList } from "./api";
import AppendMachineModal from "./appendMachineModal.vue";

export default {
  name: "massProduce",
  components: { AppendMachineModal },
  data() {
    return {
      // ztppIdList: [], // 已选择的排产表id数组，与首页表格的多选列selection有关，批量指定完工的功能

      isShowMachineModal: false,
      currentMachineList: [],
      currentZtppId: 0,

      // 表格列表的请求参数
      listParam: {
        searchType: 1, // 1:坯布指示单号,2:合同号,3:客户名称,4:款式（客户款号）
        searchText: "", // 根据searchType填写相应内容
        machineNo: "", // 机台号
        // SpecifyFinishTime: "", // 指定完工时间 如 "2021-01-12"
        ifScheduling: 2, // 状态（2全部，3 量产中、4 指定完工）

        pageNum: 1,
        pageSize: 10,
      },

      // 首页表格配置
      loading: false,
      tableColumns: [
        // {
        //   type: "selection",
        //   width: 60,
        //   align: "center",
        // },
        {
          title: "序号",
          type: "index",
          width: 66,
        },
        {
          title: "操作",
          slot: "action",
          width: 100,
        },
        {
          title: "状态",
          key: "statusName",
          width: 100,
        },
        {
          title: "预计开工日期",
          key: "predictStartDate",
          width: 130,
        },
        {
          title: "预计完工日期",
          key: "predictFinishDate",
          minWidth: 130,
        },
        {
          title: "计划下发时间",
          key: "transferOrderDate",
          width: 160,
        },
        {
          title: "坯布指示单号",
          slot: "greyClothSheetNo",
          key: "greyClothSheetNo",
          width: 140,
        },
        {
          title: "合同号",
          key: "contractNo",
          width: 140,
        },
        {
          title: "客户款号",
          key: "clientMoneyNo",
          minWidth: 170,
        },
        {
          title: "坯布货号",
          key: "greyClothGoodsNo",
          width: 120,
        },
        {
          title: "机台号",
          key: "machineNo",
          minWidth: 120,
        },
        {
          title: "指定完工人员",
          key: "SpecifyFinishPerson",
          width: 130,
        },
        {
          title: "指定完工时间",
          key: "SpecifyFinishTime",
          width: 160,
        },
        {
          title: "订单需求数",
          key: "orderNeedNum",
          width: 110,
        },
        {
          title: "计划数(kg)",
          key: "ztgcpoiPlanNum",
          width: 110,
        },
        {
          title: "计划匹数",
          key: "ztgcpoiClothNum",
          width: 100,
        },
        {
          title: "计划匹重",
          key: "planPieceWeight",
          width: 100,
        },
        {
          title: "进仓数量",
          key: "ztgctWeight",
          width: 100,
        },
        {
          title: "进仓匹数",
          key: "ztgctClothQuantity",
          width: 100,
        },
        {
          title: "计划日期",
          key: "planDate",
          minWidth: 120,
        },
        {
          title: "交货日期",
          key: "deliveryDate",
          minWidth: 120,
        },
        {
          title: "客户名称",
          key: "clientName",
          minWidth: 140,
        },

        {
          title: "颜色",
          key: "color",
          minWidth: 160,
        },
        {
          title: "提示",
          key: "prompt",
          minWidth: 100,
        },
        {
          title: "备注",
          key: "remark",
          minWidth: 220,
        },
        {
          title: "修改日志",
          key: "modifyLog",
          minWidth: 400,
        },
      ],
      tableData: [],

      listDataTotal: 0, // 分页器数据总条数

      isShowselectMachineModal: false, // 选择机台模态框是否显示

      searchTypeList, // 首页——搜索类型的 Select 下拉框列表
      productionStatusList, // 首页——状态的 Select 下拉框列表
    };
  },

  created() {
    this.getTableListData();
  },
  methods: {
    handleDateChange(formatDate, DateObj) {
      this.listParam.SpecifyFinishTime = formatDate;
      this.getTableListData();
    },

    getTableListData() {
      this.loading = true;
      fetchProductPlanList(this.listParam)
        .then((res) => {
          if (res.data.success === 1) {
            const { list } = res.data.body;

            this.tableData = list.list;
            this.listDataTotal = list.total;

            this.loading = false;
          } else {
            this.$Message.error(res.data.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    pageSizeChange(pageSize) {
      this.listParam.pageSize = pageSize;
      this.listParam.pageNum = 1;
      this.getTableListData();
    },
    pageNumberChange(pageNum) {
      this.listParam.pageNum = pageNum;
      this.getTableListData();
    },

    // 首页多选发生改变
    // selectionChange(selection) {
    //   this.ztppIdList = selection.map((item) => ({
    //     ztppId: item.ztppId,
    //     status: item.status,
    //   }));
    // },

    // 指定完工(可以同时指定多个，多选)
    // completeProduction() {
    //   if (this.ztppIdList.length === 0) {
    //     this.$Message.warning("请先选择量产中的机台！");
    //     return;
    //   }

    //   // 注意：every()方法如果测试的是 空数组 也会返回 true
    //   if (!this.ztppIdList.every((item) => item.status === 3)) {
    //     this.$Message.warning("只有量产中的机台才可以指定完工！");
    //     return;
    //   }

    //   const ztppIds = this.ztppIdList.map((item) => item.ztppId);

    //   fetchCompleteProduction([...ztppIds])
    //     .then((res) => {
    //       if (res.data.success === 1) {
    //         this.$Message.success("指定完工操作成功！");
    //         this.getTableListData();
    //       } else {
    //         this.$Message.error(res.data.msg);
    //       }
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // },

    // 导出表格
    exportTableData() {
      const { pageNum, pageSize, ...data } = this.listParam;
      const dataStr = this.qs.stringify(data);
      window.location.href = `${this.baseUrl}/dtsum/zongtong/order/ProductPlanController/productPlanExport?${dataStr}`;
    },

    // 跳转到对应的坯布指示单坯布生产查询详情页面
    goToDetailPage({ greyClothSheetNo }) {
      this.$router.push({
        path: "/workOrderManage/clothSheetDetail",
        query: { ztgcpoiWeaveDetail: greyClothSheetNo },
      });
    },

    clickAppendMachine(row) {
      /**
       * 后端已经做了判断并且业务逻辑上是不会存在这样的漏洞的，
       * 所以不需要考虑 分割的是 空字符串或者多了几个分隔符（这里是逗号）的情况
       **/
      this.currentMachineList = row.machineNo.split(",");

      this.currentZtppId = row.ztppId;
      this.isShowMachineModal = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/css/common.scss";
</style>


<style lang="scss">
#page-mass-produce {
}
</style>>

