<template>
  <!-- 追加机台弹窗 -->
  <Modal
    class="select-machine-modal"
    :width="860"
    v-model="isShowMachineModal"
    title="追加机台"
    @on-ok="handleSubmit"
    @on-cancel="handleCancel"
    @on-visible-change="modalVisibleChange"
  >
    <!-- 机台表格的筛选部分 -->
    <Row style="margin-bottom: 20px" :gutter="20" justify="space-between">
      <Col span="8">
        <Input
          style="min-width: 200px"
          placeholder="通过机台号搜索"
          v-model.trim="machineListParam.machineNo"
          @input="getMachineTableListData"
        />
      </Col>
      <Col span="8">
        <Input
          placeholder="通过货号搜索"
          v-model.trim="machineListParam.greyClothItemNo"
          @input="getMachineTableListData"
        />
      </Col>
      <Col span="6"
        ><div style="display: flex; align-items: center; white-space: nowrap">
          <span style="padding-right: 10px">状态</span>
          <Select v-model="machineListParam.status" @on-change="getMachineTableListData">
            <Option v-for="item in deviceStatusList" :value="item.value" :key="item.value">{{
              item.label
            }}</Option>
          </Select>
        </div>
      </Col>
    </Row>
    <div class="selectedMachineRow">
      <Row>
        <Col>
          已添加的机台：<span style="color: #256de6; margin-left: 10px">{{
            currentDeviceList.join("、")
          }}</span>
        </Col>
      </Row>
      <Row>
        <Col>
          待追加的机台：<span style="color: #256de6; margin-left: 10px">{{
            appendedDeviceList.join("、")
          }}</span>
        </Col>
      </Row>
    </div>
    <Table
      max-height="280"
      ref="selection"
      :loading="machineLoading"
      :columns="machineTableColumns"
      :data="machineTableData"
      @on-selection-change="machineSelectionChange"
    >
    </Table>
  </Modal>
</template>

<script>
import { deviceStatusList } from "./constant";
import { fetchAppendDevice, fetchDeviceList } from "./api";
export default {
  props: {
    isShowModal: {
      type: Boolean,
      required: true,
      default: false,
    },
    ztppId: {
      type: Number,
      required: true,
      default: 0,
    },
    currentDeviceList: {
      type: Array,
      required: true,
      default: () => [], // 形如 ["201#", "304#", "305#"]
    },
  },
  data() {
    return {
      deviceStatusList,
      isShowMachineModal: this.isShowModal,
      appendedDeviceList: [], // 机台弹窗内所有选中机台的编号数组

      // 机台弹窗的表格配置
      machineListParam: {
        status: -1, // 状态（-1 全部，1 空闲， 2 在产）
        machineNo: "",
        greyClothItemNo: "",
      },

      machineLoading: false,
      machineTableColumns: [
        {
          type: "selection",
          width: 60,
          align: "center",
        },
        {
          title: "机台号",
          key: "machineNo",
          minWidth: 120,
        },
        {
          title: "设备类型",
          key: "deviceType",
          minWidth: 120,
        },
        {
          title: "状态",
          key: "status",
          minWidth: 120,
        },
        {
          title: "坯布货号",
          key: "greyClothItemNo",
          minWidth: 120,
        },
        {
          title: "客户款号",
          key: "clientNum",
          minWidth: 120,
        },
        {
          title: "预计完工日期",
          key: "maxDate",
          minWidth: 120,
        },
      ],
      machineTableData: [],
    };
  },
  watch: {
    isShowModal: {
      handler(newVal, oldVal) {
        newVal === true && this.getMachineTableListData();
        this.isShowMachineModal = newVal;
      },
      immediate: true,
    },
  },
  methods: {
    getMachineTableListData() {
      this.machineLoading = true;
      fetchDeviceList(this.machineListParam)
        .then((res) => {
          if (res.data.success === 1) {
            const { deviceList } = res.data.body;

            for (let i = 0; i < deviceList.length; i++) {
              for (let j = 0; j < this.currentDeviceList.length; j++) {
                if (deviceList[i].machineNo === this.currentDeviceList[j]) {
                  deviceList[i]._checked = true; // 默认加上已选中项的复选框
                  deviceList[i]._disabled = true; // 默认加上禁止选择的复选框
                }
              }
            }

            this.machineTableData = deviceList;
          } else {
            this.$Message.error(res.data.msg);
          }
          this.machineLoading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 选择好之后确定提交，通知父组件 替换当前所选择的设备编号列表
    handleSubmit() {
      const ztppMachineNoList = [...this.currentDeviceList, ...this.appendedDeviceList].join(",");
      fetchAppendDevice({ ztppId: this.ztppId, ztppMachineNoList })
        .then((res) => {
          if (res.data.success === 1) {
            this.$Message.success(res.data.msg);
            this.$emit("on-success");
          } else {
            this.$Message.error(res.data.msg);
          }
          this.machineLoading = false;
        })
        .catch((err) => {
          console.log(err);
        });

      this.$emit("visible-change", false);
    },

    handleCancel() {
      this.$emit("visible-change", false);
    },

    modalVisibleChange(isVisible) {
      if (!isVisible) {
        this.machineListParam = {
          status: -1, // 状态（-1 全部，1 空闲， 2 在产）
          machineNo: "",
          greyClothItemNo: "",
        };

        this.appendedDeviceList = [];
      }
    },

    machineSelectionChange(selection) {
      const appendedRow = selection
        .map((item) => item.machineNo)
        .filter((machineNo) => {
          let flag = true;
          for (let i = 0; i < this.currentDeviceList.length; i++) {
            const selectedMachineNo = this.currentDeviceList[i];
            if (selectedMachineNo === machineNo) {
              flag = false;
              break;
            }
          }
          return flag;
        });

      this.appendedDeviceList = appendedRow; // 不包括之前选择的机台，只是追加的机台列表
    },
  },
};
</script>

<style lang="scss" scoped>
// 机台模态框
.select-machine-modal {
  .selectedMachineRow {
    background-color: #f9f9f9;
    margin: 20px 0;
    padding: 10px;
  }
}
</style>
