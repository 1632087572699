// 搜索类型的 Select 下拉框列表
const searchTypeList = [
  {
    value: 1,
    label: "坯布指示单号"
  },
  {
    value: 2,
    label: "合同号"
  },
  {
    value: 3,
    label: "客户名称"
  },
  {
    value: 4,
    label: "客户款号"
  }
];
// 生产状态的 Select 下拉框列表
const productionStatusList = [
  {
    value: 2,
    label: "全部"
  },
  {
    value: 3,
    label: "量产中"
  },
  {
    value: 4,
    label: "指定完工"
  }
];


// 机台状态的 Select 下拉框列表
const deviceStatusList = [
  {
    value: -1,
    label: "全部"
  },
  {
    value: 1,
    label: "空闲"
  },
  {
    value: 2,
    label: "在产"
  }
]; 


export { searchTypeList, productionStatusList, deviceStatusList };
